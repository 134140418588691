import React from "react";
import { AuthContext } from "./AuthContext";

export const useGetAuthData = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const { user, setUserData } = React.useContext(AuthContext);
  const isAuth = user.isAuth;

  return { token, userId, user, setUserData, isAuth };
};
