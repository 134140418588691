import axios from "axios";
import React from "react";
import { HOST_API } from "../../config/CONSTANTS";
import parse from "html-react-parser";
import "./StoriesPage.scss";
import dateFormat from "../../components/Helpers/DateFormatter";

export interface StoryProps {
  title: string;
  description: string;
  videoLink: string;
  updated_at: string;
}
const StoriesPage: React.FC = () => {
  const [stories, setStories] = React.useState([]);

  const getStories = React.useCallback(() => {
    axios
      .get(`${HOST_API}/stories/`)
      .then((result) => {
        result?.data && setStories(result?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    getStories();
  }, []);

  return (
    <div className="stories-page__component">
      {stories.length > 0 && (
        <div className="stories-page__wrapper">
          {stories.map((story: any, key) => (
            <StoryBlock {...story} key={key} />
          ))}
        </div>
      )}
    </div>
  );
};

const StoryBlock: React.FC<StoryProps> = ({
  title,
  description,
  videoLink,
  updated_at,
}) => {
  return (
    <div className="storiesPage-main__stories">
      <div className="storiesPage-list">
        <div className="storiesPage-element">
          <div className="storiesPage-header">
            <div className="storiesPage-title">{title}</div>
            <div className="storiesPage-date">{dateFormat(updated_at)}</div>
          </div>
          <div className="storiesPage-description">{description}</div>
          <div className="storiesPage-iframe__wrapper">{parse(videoLink)}</div>
          <div className="storiesPage-share">
            <span className="storiesPage__button">Поделиться</span>
            {/* TODO открывается в отдельном окне. у каждого репортажа - своя ссылка
        общая страница - репортажи */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoriesPage;
