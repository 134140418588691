import React from "react";
import { useForm } from "react-hook-form";
import OTextArea from "../../components/Form/Textarea";
import { nl2br } from "../../components/Helpers/nl2br";
const PersonalExperience: React.FC<{
  workExperience: string;
  editUserData: (data: any) => void;
  canEdit: boolean;
}> = ({ workExperience, editUserData, canEdit }) => {
  const [editIsOpen, editToggle] = React.useState(false);
  const { register, handleSubmit } = useForm();
  const saveForm = React.useCallback((data: any) => {
    console.log(data);
    editUserData(data);
  }, []);
  return (
    <div className="third">
      <h3>Опыт работы</h3>

      {!editIsOpen ? (
        <div>{nl2br(workExperience)} </div>
      ) : (
        <form>
          <OTextArea
            register={register}
            name="workExperience"
            style={{ width: "100%", height: "40px" }}
            value={workExperience}
          />
        </form>
      )}
      {canEdit && (
        <i
          onClick={() => {
            editToggle((prev) => !prev);
          }}
          className="icon-personal fa fa-pencil personal-information-edit-button"
          aria-hidden="true"
        ></i>
      )}
      {editIsOpen && (
        <i
          onClick={handleSubmit(saveForm)}
          className="icon-personal fa fa-floppy-o personal-information-save-button"
          aria-hidden="true"
        ></i>
      )}
    </div>
  );
};

export default PersonalExperience;
