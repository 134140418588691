import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { useGetAuthData } from "../../components/Auth/useGetAuth";
import dateFormat from "../../components/Helpers/DateFormatter";
import { HOST_API } from "../../config/CONSTANTS";
import PersonalAchievements from "./PersonalAchievements";
import PersonalAdditionalInfo from "./PersonalAdditionalInfo";
import PersonalContactInfo from "./PersonalContactInfo";
import PersonalExperience from "./PersonalExperience";
import PersonalInformation from "./PersonalInformation";
import { useEditUserData } from "./useEditUserData";
import "./UserHeader.scss";

export interface UserData {
  email: string;
  id: number;
  isAuth: boolean;
  role: string;
  profile: UserProfile;
  isActive: boolean;
}

export interface UserProfile {
  achievements: string;
  additionalSkills: string;
  birthDate: string;
  cityOfLiving: string;
  education: string;
  portfolioLink: string;
  telephoneNumber: string;
  userName: string;
  workExperience: string;
  professions: string[];
  avatarImageLink: string;
}

const UserHeader: React.FC<
  UserData & {
    canEdit: boolean;
    token: string;
  }
> = ({ email, id, isAuth, role, profile, canEdit, token, isActive }) => {
  const { editUserData } = useEditUserData();
  const { userId, setUserData } = useGetAuthData();

  const [uploadedAvatar, setUploadAvatar] = React.useState(undefined);
  const newAvatar = React.useMemo(
    () => (uploadedAvatar ? uploadedAvatar : profile?.avatarImageLink),
    [profile, uploadedAvatar]
  );

  console.log(newAvatar);
  const uploadAvatar = React.useCallback((data: any, id: any) => {
    console.log(data.target.files);
    const formData = new FormData();
    formData.append("file", data.target.files[0]);
    axios
      .post(`${HOST_API}/user/uploadAvatar/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((response) => {
        setUploadAvatar(response.data.avatarImageLink);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="common">
      <div className="fotoContactInformation">
        <div className="foto-wrapper">
          <img
            className="Foto"
            src={
              newAvatar ? `${HOST_API}${newAvatar}` : "../images/no-user.jpg"
            }
            alt="user foto"
          />
          {canEdit && (
            <div className="backgroundOfFoto">
              <label className="backgroundOfFoto">
                <i className="fa fa-upload" aria-hidden="true"></i>
                <input
                  className="inputFoto"
                  type="file"
                  onInput={(data) => uploadAvatar(data, userId)}
                />
              </label>
            </div>
          )}
        </div>
        {role === "admin" && (
          <div className="third">
            <Link to="/admin/index">Админ панель</Link>
          </div>
        )}
        <PersonalContactInfo
          cityOfLiving={profile?.cityOfLiving}
          telephoneNumber={profile?.telephoneNumber}
          email={email}
          editUserData={editUserData}
          canEdit={canEdit}
        />
        <div className="third">
          <h2 className="contactLine">Социальные сети:</h2>
          <ul>
            <li>
              <a href="#">ссылка на соц сеть</a>
            </li>
            <li>
              <a href="#">ссылка на соц сеть</a>
            </li>
            <li>
              <a href="#">ссылка на соц сеть</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="other">
        <PersonalInformation
          {...profile}
          editUserData={editUserData}
          canEdit={canEdit}
        />
        {!isActive && (
          <div className="third" style={{ backgroundColor: "#ffdada" }}>
            <div className="attention">Эта учетная запись не активна</div>
            <div>
              Это может ограничивать вас в использовании некоторых функций на
              портале.
              <br />
              Обычно мы стараемся как можно быстрее проверять пользователей и
              после проверки мы сделаем вашу учетную запись активной.
            </div>
          </div>
        )}
        <PersonalExperience
          workExperience={profile?.workExperience}
          editUserData={editUserData}
          canEdit={canEdit}
        />
        <PersonalAchievements
          achievements={profile?.achievements}
          editUserData={editUserData}
          canEdit={canEdit}
        />
        {/* <div className="third">
          <h3>Фотографии</h3>
          <div></div>
        </div> */}
        <PersonalAdditionalInfo
          additionalSkills={profile?.additionalSkills}
          editUserData={editUserData}
          canEdit={canEdit}
        />
      </div>
    </div>
  );
};

export default UserHeader;
