import axios from "axios";
import React from "react";
import { HOST_API } from "../../config/CONSTANTS";
import { AuthContext } from "./AuthContext";
import { useGetAuthData } from "./useGetAuth";

const AuthWrapper: React.FC<{ children: any }> = ({ children }) => {
  const { token, userId } = useGetAuthData();
  const defaultUserData = {
    id: userId,
    name: "",
    isAuth: false,
  };
  const [user, setUser] = React.useState(defaultUserData);
  const checkAuth = React.useCallback(() => {
    console.log("checkAuth");
    if (!token || !userId) {
      return;
    }
    axios
      .get(`${HOST_API}/auth/checkAuth/${user.id}`, {
        headers: { Authorization: token },
      })
      .then((result) => {
        const userId = result?.data?.id;
        if (result?.data) {
          if (Number(userId) === Number(user.id)) {
            setUser(result?.data && { ...result?.data, isAuth: true });
          } else {
            setUser(defaultUserData);
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  React.useEffect(() => {
    checkAuth();
  }, []);
  return (
    <AuthContext.Provider value={{ user, setUserData: setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
