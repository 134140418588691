import React from "react";
import { useForm } from "react-hook-form";
import OInput from "../../components/Form/Input";
import OTextArea from "../../components/Form/Textarea";
import { nl2br } from "../../components/Helpers/nl2br";
const PersonalContactInfo: React.FC<{
  cityOfLiving: string;
  telephoneNumber: string;
  email: string;
  editUserData: (data: any) => void;
  canEdit: boolean;
}> = ({ cityOfLiving, telephoneNumber, email, editUserData, canEdit }) => {
  const [editIsOpen, editToggle] = React.useState(false);
  const { register, handleSubmit } = useForm();
  const saveForm = React.useCallback((data: any) => {
    console.log(data);
    editUserData(data);
  }, []);
  return (
    <div className="third">
      <h2 className="contactLine">Контактная информация:</h2>
      <ul></ul>
      {!editIsOpen ? (
        <ul>
          <li>
            <span>Город проживания: </span>
            {cityOfLiving}
          </li>
          <li>
            <span>Телефон: </span>
            {telephoneNumber}
          </li>
          <li>
            <span>e-mail: </span>
            {email}
          </li>
        </ul>
      ) : (
        <form>
          <OInput
            register={register}
            name="cityOfLiving"
            title="Город проживания"
            value={cityOfLiving}
            style={{ width: "250px" }}
          />
          <OInput
            register={register}
            name="telephoneNumber"
            title="Телефонный номер"
            value={telephoneNumber}
            style={{ width: "250px" }}
          />
        </form>
      )}
      {canEdit && (
        <i
          onClick={() => {
            editToggle((prev) => !prev);
          }}
          className="icon-personal fa fa-pencil personal-information-edit-button"
          aria-hidden="true"
        ></i>
      )}
      {editIsOpen && (
        <i
          onClick={handleSubmit(saveForm)}
          className="icon-personal fa fa-floppy-o personal-information-save-button"
          aria-hidden="true"
        ></i>
      )}
    </div>
  );
};

export default PersonalContactInfo;
