import React from "react";
import "./TextBlock.scss";

const TextBlock: React.FC<{ text: string; sight: string }> = ({
  text,
  sight,
}) => {
  return (
    <div className="first-description-block">
      <div className="main_text-block">
        {text}
        <div className="main_sigh-block">
          <div className="sight-block_text">{sight}</div>
        </div>
      </div>
    </div>
  );
};

export default TextBlock;
