import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import "./TextArea.scss";

export interface OTextareaProps {
  register: UseFormRegister<FieldValues>;
  name: string;
  className?: string;
  value?: string;
  title?: string;
  style?: any;
}
const OTextArea: React.FC<OTextareaProps> = ({
  register,
  name,
  className = "o-textarea__default-style",
  value,
  title,
  style,
}) => {
  return (
    <label>
      {title && <div className="o-textarea__title">{title}</div>}
      <textarea
        {...register(name)}
        className={className}
        defaultValue={value}
        style={style}
      ></textarea>
    </label>
  );
};
export default OTextArea;
