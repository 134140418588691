import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { ArticleProps } from "../../components/Articles/Articles";
import dateFormat from "../../components/Helpers/DateFormatter";
import { HOST_API } from "../../config/CONSTANTS";
import "./ArticlesPage.scss";

const ArticlesPage: React.FC = () => {
  const [articles, setArticles] = React.useState([]);

  const getArticles = React.useCallback(() => {
    axios
      .get(`${HOST_API}/articles/`)
      .then((result) => {
        result?.data && setArticles(result?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    getArticles();
  }, []);
  return (
    <div className="articles-page__component">
      <div className="main-article">
        {articles.length > 0 &&
          articles.map(
            (article: ArticleProps & { id: string | number }, key) => (
              <Article {...article} key={key} />
            )
          )}
      </div>
    </div>
  );
};

const Article: React.FC<ArticleProps & { id: string | number }> = ({
  id,
  title,
  description,
  imageLink,
  created_at,
}) => {
  return (
    <div className="article-articles">
      <div className="article-entire">
        <div className="article-title">
          <h2 className="article-name">{title}</h2>
          <p className="article-date">{dateFormat(created_at)}</p>
        </div>
        <div className="article-view">
          <img src={`${imageLink}`} className="article-image" />
          <p className="article-prereading">{description}</p>
          <div className="button-wrapper">
            <Link to={`${id}`}>
              <button className="button">Читать</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArticlesPage;
