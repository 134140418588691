import React from "react";
import { Link } from "react-router-dom";
import "./AdminPage.scss";

const AdminLeftPanel: React.FC<{ mainSection: string }> = ({ mainSection }) => {
  return (
    <div className="admin-page__leftPanel">
      <ul>
        <li>
          <Link to={`${mainSection}/images`}>Изображения</Link>
        </li>
        <li>
          <Link to={`${mainSection}/articles`}>Статьи</Link>
        </li>
        <li>
          <Link to={`${mainSection}/stories`}>Репортажи</Link>
        </li>
        <li>
          <Link to={`${mainSection}/professions`}>Профессии</Link>
        </li>
        <li>
          <Link to={`${mainSection}/users`}>Пользователи</Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminLeftPanel;
