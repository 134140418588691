import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useGetAuthData } from "../../../components/Auth/useGetAuth";
import { HOST_API } from "../../../config/CONSTANTS";
import "./ImagesPage.scss";

const ImagesPage: React.FC = () => {
  const { handleSubmit, register, watch, setValue } = useForm();
  // const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const uploadedImages = watch("files");
  const [imagePreviews, setImagePreviews] = React.useState<any[]>([]);

  const { token } = useGetAuthData();

  React.useEffect(() => {
    // console.log(uploadedImages);
    if (uploadedImages && [...uploadedImages]?.length !== 0) {
      createImagePreview();
    }
  }, [uploadedImages]);

  React.useEffect(() => {
    getImages();
    setValue("files", "");
    setImagePreviews([]);
    console.log(images);
  }, []);

  const createImagePreview = React.useCallback(() => {
    setImagePreviews([]);

    [...uploadedImages]?.forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (res) => {
        setImagePreviews((prev) => [...prev, [reader.result]]);
      };
    });
  }, [uploadedImages]);

  const onSubmit = React.useCallback((data: any) => {
    console.log(data);
    if (data?.files && token) {
      const formData = new FormData();
      [...data.files].forEach((file) => {
        formData.append("files", file);
      });
      console.log(formData);
      axios
        .post(`${HOST_API}/images/upload`, formData, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          setValue("files", "");
          setImagePreviews([]);
          getImages();
          console.log(result);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const getImages = React.useCallback(() => {
    axios
      .get(`${HOST_API}/images/`, { headers: { Authorization: token } })
      .then((response) => {
        if (response?.data?.length !== 0) {
          console.log(response);
          setImages(response?.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="imagesPage__component">
      <h1>Загрузите ваши фотографии на портал</h1>
      <div className="add-image-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="inputDesign">
            <input
              className="input-file"
              type="file"
              multiple={true}
              {...register("files")}
            ></input>
            <span className="icon-wrapper">
              <i className="fa fa-folder-open icon" aria-hidden="true"></i>
            </span>
            <span className="text-wrapper"> Выбрать...</span>
          </label>
          <div className="images-preview">
            {imagePreviews &&
              imagePreviews.map((image: any, key) => (
                <img
                  src={image}
                  key={key}
                  className="admin-page-images_image"
                />
              ))}
          </div>
          <label className="inputDesign">
            <span className="icon-wrapper">
              <i className="fa fa-download icon" aria-hidden="true"></i>
            </span>
            <input type="submit" className="input-file"></input>
            <span className="text-wrapper">Загрузить...</span>
          </label>
        </form>
      </div>
      <div className="list-of-Images">
        {images?.map((image: any, key) => (
          <Image
            key={key}
            link={image.link}
            id={image.id}
            setImages={setImages}
          />
        ))}
      </div>
    </div>
  );
};

const Image: React.FC<{
  id: number;
  link: string;
  key: string | number;
  setImages: React.Dispatch<React.SetStateAction<never[]>>;
}> = ({ id, link, key, setImages }) => {
  const { token } = useGetAuthData();
  const DeleteImage = React.useCallback((id: number) => {
    axios
      .post(
        `${HOST_API}/images/remove`,
        { id },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((result) => {
        console.log(result);
        setImages((prev) => {
          return prev.filter((item: any) => item?.id !== id);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const AddImageToBuffer = React.useCallback(() => {
    function fallbackCopyTextToClipboard(link: any) {
      const textArea = document.createElement("textarea");
      textArea.value = link;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
    const textToCopy = `${HOST_API}${link}`;
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(textToCopy);
      return;
    }
    const data = [
      new ClipboardItem({
        "text/plain": new Blob([textToCopy], { type: "text/plain" }),
      }),
    ];
    navigator.clipboard.write(data).then(
      function () {
        console.log("Copied to clipboard successfully!");
      },
      function () {
        console.error("Unable to write to clipboard. :-(");
      }
    );
  }, []);
  return (
    <div className="admin-page-images_mainwrapper" key={key}>
      <img src={`${HOST_API}${link}`} className="admin-page-images_image" />
      <div className="admin-page-images_wrapper">
        <button
          className="admin-images_button"
          onClick={() => AddImageToBuffer()}
        >
          <i className="fa fa-files-o" aria-hidden="true"></i>
        </button>
        <button className="admin-images_button" onClick={() => DeleteImage(id)}>
          <i className="fa fa-trash-o" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default ImagesPage;
