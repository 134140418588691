import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { ArticleProps } from "../../../components/Articles/Articles";
import { useGetAuthData } from "../../../components/Auth/useGetAuth";
import OInput, { InputTypeEnum } from "../../../components/Form/Input";
import OTextArea from "../../../components/Form/Textarea";
import { genKey } from "../../../components/Helpers/genKey";
import { HOST_API } from "../../../config/CONSTANTS";
import "./ArticlesPage.scss";

const ArticlesPage: React.FC = () => {
  const [articles, setArticles] = React.useState([]);

  const getArticlesList = React.useCallback(() => {
    axios
      .get(`${HOST_API}/articles/`, {})
      .then((response) => {
        setArticles(response.data);
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  React.useEffect(() => {
    getArticlesList();
  }, []);
  return (
    <div className="imagesPage__component">
      <h1>Статьи</h1>
      <AddArticlesForm setArticles={setArticles} />
      <ArticlesList articles={articles} setArticles={setArticles} />
    </div>
  );
};

const AddArticlesForm: React.FC<{ setArticles: any }> = ({ setArticles }) => {
  const { register, handleSubmit } = useForm();
  const { userId, token } = useGetAuthData();
  const SubmitForm = React.useCallback((data: any) => {
    axios
      .post(`${HOST_API}/articles/add`, data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setArticles((prev: any) =>
          [...prev, response.data].sort((a: any, b: any) => b.id - a.id)
        );
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="add-stories__component">
      <h3>Добавить новую статью</h3>
      <form className="add-stories__form" onSubmit={handleSubmit(SubmitForm)}>
        <OInput name="title" title="Заголовок" register={register} />
        <OInput name="description" title="Описание" register={register} />
        {/* <OInput name="text" title="Текст" register={register} /> */}
        <OTextArea name="text" title="Текст" register={register} />
        <OInput
          name="imageLink"
          title="ссылка на изображение"
          register={register}
        />
        <OInput
          name="user"
          title="Пользователь"
          type={InputTypeEnum.hidden}
          register={register}
          value={userId || 0}
        />
        <input type="submit" className="submit-button__form" value="Создать" />
      </form>
    </div>
  );
};

const ArticlesList: React.FC<{ articles: any[]; setArticles: any }> = ({
  articles,
  setArticles,
}) => {
  return (
    <div className="articles-list__component">
      <h3 className="admin-field__edit">Редактировать статьи</h3>
      {articles.length > 0 &&
        articles.map((article: any, key) => (
          <UpdateArticle
            {...article}
            key={genKey(key)}
            setArticles={setArticles}
          />
        ))}
    </div>
  );
};

const UpdateArticle: React.FC<
  ArticleProps & { id: string | number; setArticles: (prev: any) => void }
> = ({ id, title, description, imageLink, text, setArticles }) => {
  const { register, handleSubmit } = useForm();
  const { userId, token } = useGetAuthData();
  const SubmitForm = React.useCallback((data: any) => {
    axios
      .post(`${HOST_API}/articles/update/${id}`, data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const removeArticle = React.useCallback(() => {
    axios
      .post(
        `${HOST_API}/articles/remove/${id}`,
        {},
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setArticles((prev: any) => prev.filter((item: any) => item.id !== id));
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="pages-areticles__editArticles">
      <form className="add-stories__form" onSubmit={handleSubmit(SubmitForm)}>
        <OInput
          name="title"
          title="Заголовок"
          register={register}
          value={title}
        />
        <OInput
          name="description"
          title="Описание"
          register={register}
          value={description}
        />
        <OTextArea
          value={text}
          register={register}
          name={"text"}
          title={"текст статьи "}
        />
        <OInput
          name="imageLink"
          title="Ссылка на изображение"
          register={register}
          value={imageLink}
        />
        <img
          src={`${imageLink}`}
          width="200px"
          className="pages-articles__img"
        />
        <OInput
          name="user"
          title="user"
          register={register}
          value={userId || 0}
          type={InputTypeEnum.hidden}
        />
        <input
          type="submit"
          className="submit-button__form"
          value="Редактировать"
        />
      </form>
      <button className="pages-articles_buttonDelete" onClick={removeArticle}>
        Удалить
      </button>
    </div>
  );
};
export default ArticlesPage;
