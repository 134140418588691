import axios from "axios";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useGetAuthData } from "../../../components/Auth/useGetAuth";
import OInput, { InputTypeEnum } from "../../../components/Form/Input";
import OTextArea from "../../../components/Form/Textarea";
import { genKey } from "../../../components/Helpers/genKey";
import { HOST_API } from "../../../config/CONSTANTS";
import { StoryProps } from "../../Stories/StoriesPage";
import "./StoriesPage.scss";

const StoriesPage: React.FC = () => {
  const [stories, setStories] = React.useState([]);

  const getStoriessList = React.useCallback(() => {
    axios
      .get(`${HOST_API}/stories/`, {})
      .then((response) => {
        setStories(response.data);
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  React.useEffect(() => {
    getStoriessList();
  }, []);

  return (
    <div className="imagesPage__component">
      <h1>Stories</h1>
      <AddStoriesForm setStories={setStories} />
      <StoriesList stories={stories} setStories={setStories} />
    </div>
  );
};

const AddStoriesForm: React.FC<{ setStories: any }> = ({ setStories }) => {
  const { register, handleSubmit } = useForm();
  const { userId, token } = useGetAuthData();
  const SubmitForm = React.useCallback((data: any) => {
    axios
      .post(`${HOST_API}/stories/add`, data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setStories((prev: any) =>
          [...prev, response.data].sort((a: any, b: any) => b.id - a.id)
        );
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div className="add-stories__component">
      <h3>Добавить новый репортаж</h3>
      <form className="add-stories__form" onSubmit={handleSubmit(SubmitForm)}>
        <OInput name="title" title="Заголовок" register={register} />
        <OInput name="description" title="Описание" register={register} />
        <OInput name="videoLink" title="ВидеоСсылка" register={register} />
        <OInput
          name="user"
          title="Заголовок"
          type={InputTypeEnum.hidden}
          register={register}
          value={userId || 0}
        />
        <input type="submit" className="submit-button__form" value="Создать" />
      </form>
    </div>
  );
};

const StoriesList: React.FC<{
  stories: any[];
  setStories: (prev: any) => void;
}> = ({ stories, setStories }) => {
  return (
    <div className="articles-list__component">
      <h3 className="admin-field__edit">Редактировать репортажи</h3>
      {stories.length > 0 &&
        stories.map((article: StoryProps & { id: number | string }, key) => (
          <EditStoryForm
            {...article}
            key={genKey(key)}
            setStories={setStories}
          />
        ))}
    </div>
  );
};

const EditStoryForm: React.FC<
  StoryProps & { id: number | string; setStories: (prev: any) => void }
> = ({ id, title, description, videoLink, setStories }) => {
  const { userId, token } = useGetAuthData();
  const { register, handleSubmit } = useForm();

  const SubmitForm = React.useCallback((data: any) => {
    axios
      .post(`${HOST_API}/stories/update/${id}`, data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const removeStories = React.useCallback(() => {
    axios
      .post(
        `${HOST_API}/stories/remove/${id}`,
        {},
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setStories((prev: any) => prev.filter((item: any) => item.id !== id));
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <>
      <form className="add-stories__form" onSubmit={handleSubmit(SubmitForm)}>
        <OInput
          name="title"
          title="Заголовок"
          register={register}
          value={title}
        />
        <OInput
          name="description"
          title="Описание"
          register={register}
          value={description}
        />
        <OTextArea
          name="videoLink"
          title="ВидеоСсылка"
          register={register}
          value={videoLink}
        />
        <OInput
          name="user"
          title="Заголовок"
          type={InputTypeEnum.hidden}
          register={register}
          value={userId || 0}
        />
        <input
          type="submit"
          className="submit-button__form"
          value="Редактировать"
        />
      </form>
      <button className="pages-articles_buttonDelete" onClick={removeStories}>
        Удалить
      </button>
    </>
  );
};
export default StoriesPage;
