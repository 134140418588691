import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetAuthData } from "../../components/Auth/useGetAuth";
import { HOST_API } from "../../config/CONSTANTS";
import "./AuthPage.scss";

const AuthPage: React.FC = () => {
  const [isActive, setIsActive] = React.useState("login");
  const [error, setError] = React.useState("");
  return (
    <div className="auth-page__component">
      <div className="main-form">
        <div className="signupOrlogin">
          <div
            className={`sing-up ${isActive === "register" ? "active" : ""}`}
            onClick={() => setIsActive("register")}
          >
            <span className="wrapper-main">Зарегистрироваться</span>
          </div>
          <div
            className={`login ${isActive === "login" ? "active" : ""}`}
            onClick={() => setIsActive("login")}
          >
            <span className="wrapper-main">Войти</span>
          </div>
        </div>
        {isActive === "login" ? (
          <Login setError={setError} />
        ) : (
          <Register setError={setError} />
        )}
        {error && <div>{error}</div>}
      </div>
    </div>
  );
};

const Login: React.FC<{ setError: any }> = ({ setError }) => {
  const { register, watch, handleSubmit } = useForm();
  const { user, setUserData } = useGetAuthData();
  const navigate = useNavigate();
  return (
    <div className="auth-page__component">
      <div className="login-component">
        <div>
          <form
            className="form"
            onSubmit={handleSubmit((data) =>
              onSubmitLogin(data, setUserData, setError, navigate)
            )}
          >
            <div className="auth-page__title">Войти</div>
            <label className="field">
              <span className="wrapper">
                <i className="fa fa-envelope icon-login" aria-hidden="true"></i>
              </span>
              <input
                type="email"
                {...register("email")}
                placeholder="email"
                className="put-information"
              />
            </label>
            <label className="field">
              <span className="wrapper">
                <i className="fa fa-lock icon-login" aria-hidden="true"></i>
              </span>
              <input
                type="password"
                {...register("password")}
                placeholder="пароль"
                className="put-information"
              />
            </label>
            <input type="submit" value="Войти" className="submit-button" />
          </form>
        </div>
      </div>
    </div>
  );
};

const Register: React.FC<{ setError: any }> = ({ setError }) => {
  const { register, watch, handleSubmit } = useForm();
  const { user, setUserData } = useGetAuthData();
  const navigate = useNavigate();
  return (
    <div className="auth-page__component">
      <div className="login-component">
        <div>
          <form
            className="form"
            onSubmit={handleSubmit((data) =>
              onSubmitRegister(data, setUserData, setError, navigate)
            )}
          >
            <div className="auth-page__title">Зарегистрироваться</div>
            <label className="field">
              <span className="wrapper">
                <i className="fa fa-user icon-login " aria-hidden="true"></i>
              </span>
              <input
                type="text"
                {...register("name")}
                placeholder="ФИО"
                className="put-information"
              />
            </label>
            <label className="field">
              <span className="wrapper">
                <i className="fa fa-envelope icon-login" aria-hidden="true"></i>
              </span>
              <input
                type="email"
                {...register("email")}
                placeholder="email"
                className="put-information"
              />
            </label>
            <label className="field">
              <span className="wrapper">
                <i className="fa fa-lock icon-login" aria-hidden="true"></i>
              </span>
              <input
                type="password"
                {...register("password")}
                placeholder="пароль"
                className="put-information"
              />
            </label>
            <label className="field">
              <span className="wrapper">
                <i className="fa fa-lock icon-login" aria-hidden="true"></i>
              </span>
              <input
                type="password"
                {...register("confirmPassword")}
                placeholder="повторите пароль"
                className="put-information"
              />
            </label>
            <label className="authCheckBox-wrapper">
              <input
                className="authCheckBox"
                type="checkbox"
                value="company"
                {...register("type")}
              />
              Я являюсь заказчиком
            </label>
            <input
              type="submit"
              value="Зарегистрироваться"
              className="submit-button"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export const onSubmitLogin = (
  data: any,
  setState: any,
  setError: any,
  navigate: any
) => {
  setError("");
  axios
    .post(`${HOST_API}/auth/userLogin`, { ...data })
    .then((result) => {
      if (result.data.status !== 200 || result.data.status !== 201) {
        setError(result?.data?.message);
      }
      const token = result?.data?.token?.token;
      const userId = result?.data?.user?.id;
      if (token && userId) {
        localStorage.setItem("token", `Bearer ${token}`);
        localStorage.setItem("userId", userId);
        setState(result?.data?.user && { ...result?.data.user, isAuth: true });
        navigate(`/users/${userId}`);
      }
    })
    .catch((err) => {
      setError(err);
      console.error(err);
    });
};

const onSubmitRegister = (
  data: any,
  setState: any,
  setError: any,
  navigate: any
) => {
  setError("");
  if (data.password !== data.confirmPassword) {
    setError("пароли не совпадают");
    return;
  }
  axios
    .post(`${HOST_API}/auth/addNewUser`, { ...data })
    .then((result) => {
      console.log(result);
      if (result.data.status !== 200 || result.data.status !== 201) {
        setError(result?.data?.message);
      }
      const token = result?.data?.token?.token;
      const userId = result?.data?.user?.id;
      if (token && userId) {
        localStorage.setItem("token", `Bearer ${token}`);
        localStorage.setItem("userId", userId);
        setState(result?.data?.user && { ...result?.data.user, isAuth: true });
        navigate(`/users/${userId}`);
      }
    })
    .catch((err) => {
      setError(err.message);
      console.error(err);
    });
  console.log(data);
};
export default AuthPage;
