import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { genKey } from "../Helpers/genKey";
import "./Select.scss";

export interface OSelectProps {
  register: UseFormRegister<FieldValues>;
  name: string;
  className?: string;
  value?: string;
  title?: string;
  options: { id: number; value: string }[];
  multiple: boolean;
}
const OSelect: React.FC<OSelectProps> = ({
  register,
  name,
  className = "o-select__default-style",
  value,
  title,
  options,
  multiple = false,
}) => {
  console.log(options);
  return (
    <label>
      {title && <div className="o-select__title">{title}</div>}
      <select {...register(name)} className={className} value={value} multiple>
        {options?.map((item: any, key: number) => (
          <option key={genKey(key)} value={item.id} selected={item.selected}>
            {item.value}
          </option>
        ))}
      </select>
    </label>
  );
};
export default OSelect;
