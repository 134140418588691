import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import OInput, { InputTypeEnum } from "../../components/Form/Input";
import dateFormat from "../../components/Helpers/DateFormatter";
import { HOST_API } from "../../config/CONSTANTS";
import "./UserHeader.scss";
import "./PersonalInformation.scss";
import OSelect from "../../components/Form/Select";

export interface PersonalInformation {
  userName: string;
  birthDate: string;
  professions: string[];
  education: string;
  portfolioLink: string;
}
const PersonalInformation: React.FC<
  PersonalInformation & {
    editUserData: (data: any) => void;
    canEdit: boolean;
  }
> = ({
  userName,
  birthDate,
  professions,
  education,
  portfolioLink,
  editUserData,
  canEdit,
}) => {
  console.log(professions);
  const [professionsList, setProfessionsList] = React.useState([]);
  const [editIsOpen, editToggle] = React.useState(false);
  const { register, handleSubmit } = useForm();

  const saveForm = React.useCallback((data: any) => {
    console.log(data);
    editUserData(data);
  }, []);

  React.useEffect(() => {
    axios
      .get(`${HOST_API}/professions/`)
      .then((result) => {
        setProfessionsList(result?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="PersonalInformation third">
      {!editIsOpen ? (
        <div>
          <h2>{userName || "Имя не заполнено"}</h2>
          <ul className="list">
            <li>
              Дата рождения:
              <span> {dateFormat(birthDate || new Date().toUTCString())}</span>
            </li>
            <li>
              Профессия:
              {professions?.map((item: any, key: number) => (
                <span key={key}>{item?.name} </span>
              ))}
            </li>
            <li>Образование: {education} </li>
            <li>
              Портфолио: <a href={portfolioLink}> ссылка на портфолио</a>
            </li>
          </ul>{" "}
        </div>
      ) : (
        <form className="personalInformation-form_output">
          <div className="personalInformation-block">
            <div className="personalInformation-firstBlock">
              <OInput
                register={register}
                name="userName"
                title="ФИО"
                value={userName || ""}
              />
              <OInput
                type={InputTypeEnum.date}
                name="birthDate"
                register={register}
                value={birthDate || ""}
                title="Дата рождения"
              />
              <OInput
                register={register}
                name="education"
                title="Образование"
                value={education || ""}
              />
              <OInput
                register={register}
                name="portfolioLink"
                title="Ссылка на портфолио"
                value={portfolioLink}
              />
            </div>
            <div className="personalInformation-secondBlock"></div>
            <OSelect
              register={register}
              name="profession"
              multiple
              title="Профессия"
              className="personal-information__select"
              options={professionsList.map((prof: any) => ({
                id: prof.id,
                value: prof.name,
                selected: professions.some((item: any) => item?.id === prof.id),
              }))}
            />
          </div>
        </form>
      )}
      {canEdit && (
        <i
          onClick={() => {
            editToggle((prev) => !prev);
          }}
          className="icon-personal fa fa-pencil personal-information-edit-button"
          aria-hidden="true"
        ></i>
      )}
      {editIsOpen && (
        <i
          onClick={handleSubmit(saveForm)}
          className="icon-personal fa fa-floppy-o personal-information-save-button"
          aria-hidden="true"
        ></i>
      )}
    </div>
  );
};

export default PersonalInformation;
