const dateFormat = (utc: string, separator = "-") => {
  const date = new Date(utc);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return day + separator + month + separator + year;
};

export default dateFormat;
