import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { HOST_API } from "../../config/CONSTANTS";
import dateFormat from "../Helpers/DateFormatter";
import { genKey } from "../Helpers/genKey";
import "./Articles.scss";

export interface ArticleProps {
  id: number;
  title: string;
  description: string;
  imageLink: string;
  created_at: string;
  updated_at: string;
  text: string;
  userId?: number;
}

const ArticlesComponent: React.FC = () => {
  const [articles, setArticles] = React.useState([]);

  const getArticles = React.useCallback((limit: number) => {
    axios
      .get(`${HOST_API}/articles/get/${limit}`)
      .then((result) => {
        result?.data && setArticles(result?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    getArticles(4);
  }, []);
  return (
    <div className="articles-component">
      <div
        className={`${
          articles.length > 1 ? "articles-grid" : "one-article-grid"
        }`}
      >
        {articles.length > 0 &&
          articles.map((article: ArticleProps, key) => (
            <Link to={`/articles/${article.id}`} key={genKey(key)}>
              <ArticleElement {...article} />
            </Link>
          ))}
      </div>
    </div>
  );
};

const ArticleElement: React.FC<ArticleProps> = ({
  title,
  description,
  imageLink,
  created_at,
}) => {
  return (
    <div
      className="article-element"
      style={{ backgroundImage: `url(${imageLink})` }}
    >
      <div className="article-shadow"></div>
      <div className="article-component_title">{title}</div>
      <div className="article-description">{description}</div>
      <div className="article-footer">{dateFormat(created_at)}</div>
    </div>
  );
};

export default ArticlesComponent;
