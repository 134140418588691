import React from "react";
import ArticlesComponent from "../../components/Articles/Articles";
import BlogComponent from "../../components/Blog/Blog";
import SliderComponent from "../../components/Slider/Slider";
import TextBlock from "../../components/TextBlock/TextBlock";

const MainPage: React.FC = () => {
  const text = React.useMemo(
    () => `Дорогие друзья, коллеги и единомышленники. Позвольте представиться, мы -
    Медиапространство "Театральный экватор". Уже много лет наша команда
    трудится на поприще культуры и искусства нашей Белгородской области и всей
    России. Многие из нас безапелляционно связали свою жизнь с театром. Мы
    радеем за нашу родную землю и уверены, что ей уготовано звание
    по-настоящему театрального края. В данной группе, на нашем сайте и каналах
    на видеохостингах мы будем размещать самую полезную, важную и интересную
    информацию о театре, кино, а также основы и тонкости в постижении
    профессии с гордым именем - "Артист". Мы не продаем курсы и тренинги, мы
    хотим создать сообщество преданных своему делу людей, которые могли бы
    общаться, взаимодействовать и развиваться вместе. Будем рады видеть вас в
    наших рядах. Медиапространство "Театральный Экватор" Мир театра вокруг
    тебя!`,
    []
  );

  const sight = React.useMemo(
    () => `Будем рады видеть вас в наших рядах.
    Медиапространство "Театральный Экватор"
    Мир театра вокруг тебя!`,
    []
  );

  return (
    <div>
      <SliderComponent />
      <TextBlock text={text} sight={sight} />
      <BlogComponent />
      <ArticlesComponent />
    </div>
  );
};

export default MainPage;
