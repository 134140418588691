import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useRedirectToAuth } from "../../components/Auth/useRedirectToAuth";
import AdminLeftPanel from "./AdminLeftPanel";
import "./AdminPage.scss";
import ArticlesPage from "./pages/ArticlesPage";
import ImagesPage from "./pages/ImagesPage";
import Professions from "./pages/Professions";
import StoriesPage from "./pages/StoriesPage";
import UsersPage from "./pages/UsersPage";

const AdminPage: React.FC = () => {
  useRedirectToAuth();
  return (
    <div className="admin-page__component">
      <AdminLeftPanel mainSection="/admin" />
      <div className="admin-page__content">
        <Routes>
          <Route path="images" element={<ImagesPage />} />
          <Route path="articles" element={<ArticlesPage />} />
          <Route path="stories" element={<StoriesPage />} />
          <Route path="professions" element={<Professions />} />
          <Route path="users" element={<UsersPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminPage;
