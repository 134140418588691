import { FieldValues, useForm, UseFormRegister } from "react-hook-form";
import React from "react";
import "./Input.scss";

export enum InputTypeEnum {
  text = "text",
  password = "password",
  hidden = "hidden",
  submit = "submit",
  date = "date",
}

export interface InputProps {
  id?: number | string;
  register: UseFormRegister<FieldValues>;
  name: string;
  title: string;
  type?: InputTypeEnum;
  value?: string | number;
  style?: any;
}

const OInput: React.FC<InputProps> = ({
  id,
  name,
  register,
  title,
  type = "text",
  value,
  style,
}) => {
  return (
    <label
      className={`oInput-label ${
        type === InputTypeEnum.hidden ? "hidden" : ""
      }`}
    >
      <div className="input-title-label__text">{title}</div>
      <input
        {...register(name)}
        type={type}
        className="input-field__input"
        defaultValue={value}
        style={style}
      />
    </label>
  );
};

export default OInput;
