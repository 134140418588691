import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import { AuthContext } from "../Auth/AuthContext";
import { ModalContext } from "../Modal/ModalContext";
import "./Header.scss";
import { LoginComponent } from "./LoginComponent";
import { RegisterComponent } from "./RegisterComponent";
const HeaderComponent: React.FC = () => {
  return (
    <>
      <div className="header-component">
        <Logo />
        <HeaderList />
        {/* <RegisterBlock /> */}
      </div>
      <div className="_margin"></div>
    </>
  );
};

const Logo: React.FC = () => {
  return (
    <div className="logo">
      <img src="../images/logo.png" alt="logo" className="logo-image" />
    </div>
  );
};

const HeaderList: React.FC = () => {
  const { user, setUserData } = React.useContext(AuthContext);
  const [isLinksOpen, setLinksOpen] = React.useState(false);
  const navigate = useNavigate();
  const isUserAuth = React.useMemo(() => user.isAuth, [user]);
  React.useEffect(() => {
    console.log(user);
  }, [isUserAuth, user]);

  const toggleMenu = () => {
    setLinksOpen(!isLinksOpen);
  };

  const userExitHandle = React.useCallback(() => {
    setUserData({});
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/auth");
  }, []);

  return (
    <div className="header-list-container">
      <i
        aria-hidden="true"
        className="fa fa-bars header-list-button"
        onClick={toggleMenu}
      ></i>
      <ul className={`header-list ${isLinksOpen ? "show" : "hide"}`}>
        {ROUTES.map((route, i) => (
          <LinkElement key={i} name={route.name} link={route.route} />
        ))}
        {!isUserAuth ? (
          <>
            <li className="element register">
              <Link to="/auth">Войти</Link>
            </li>
          </>
        ) : (
          <>
            <UserPreview />
            <ExitBlock onClick={userExitHandle} />
          </>
        )}
      </ul>
    </div>
  );
};

const UserPreview: React.FC = () => {
  const { user } = React.useContext(AuthContext);
  return (
    <li className="element register userProfile">
      <Link to={`/users/${user.id}`}>
        {user && user?.profile?.name ? user?.profile?.name : user?.email}{" "}
      </Link>
    </li>
  );
};

const ExitBlock: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <li className="element register" onClick={onClick}>
      Выход
    </li>
  );
};

const LinkElement: React.FC<{ name: string; link: string }> = ({
  name,
  link,
}) => {
  return (
    <li className="element">
      <Link to={link}>{name}</Link>
    </li>
  );
};
export default HeaderComponent;
