import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetAuthData } from "./useGetAuth";

export const useRedirectToAuth = () => {
  const navigate = useNavigate();
  const redirect = () => navigate("/auth");
  const { token, userId } = useGetAuthData();
  React.useEffect(() => {
    if (!token || !userId) {
      redirect();
    }
  }, []);
  return redirect;
};
