import React from "react";
import "./Slider.scss";
const SliderComponent: React.FC = () => {
  const IMAGE_URL = "./images/slider2.jpg";
  return (
    <div
      className="slider-component"
      style={{ backgroundImage: `url(${IMAGE_URL})` }}
    >
      <div className="slider-title">
        Медиапространство <br />
        &quot;Театральный экватор&quot;
      </div>
      {/* <div className="slider-title2">
        Медиапространство <br />
        &quot;Театральный экватор&quot;
      </div> */}
      <div className="slider-statistics">
        <StatisticsElement number={156} text="артистов" />
        <StatisticsElement number={9} text="театров" />
        <StatisticsElement number={3} text="проекта" />
      </div>
    </div>
  );
};

interface IStatisticElement {
  number: number;
  text: string;
}
const StatisticsElement: React.FC<IStatisticElement> = ({ number, text }) => {
  return (
    <div className="statistic-element">
      <div className="stat-number">{number}</div>
      <div className="stat-text">{text}</div>
    </div>
  );
};
export default SliderComponent;
