import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetAuthData } from "../../components/Auth/useGetAuth";
import { HOST_API } from "../../config/CONSTANTS";

export const useEditUserData = () => {
  const { token, userId: selfUserId, setUserData, user } = useGetAuthData();
  // console.log(token, userId);
  const editUserData = React.useCallback((data: any) => {
    Object.keys(data).forEach((k) => data[k] == "" && delete data[k]);
    console.log(data);
    axios
      .post(
        `${HOST_API}/user/update/${selfUserId}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((result) => {
        console.log(result);
        console.log(user);
        setUserData?.((prev: any) => ({
          ...prev,
          profile: { ...prev.profile, ...result?.data },
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return { editUserData };
};
