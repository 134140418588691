import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { ArticleProps } from "../../components/Articles/Articles";
import dateFormat from "../../components/Helpers/DateFormatter";
import { HOST_API } from "../../config/CONSTANTS";
import "./ArticlePage.scss";
import parse from "html-react-parser";

const ArticlePage: React.FC = () => {
  const { articleId } = useParams();
  const [article, setArticle] = React.useState<ArticleProps | undefined>(
    undefined
  );
  const getArticleById = React.useCallback(() => {
    if (!articleId) {
      return;
    }
    axios
      .get(`${HOST_API}/articles/${articleId}`)
      .then((result) => {
        result?.data && setArticle(result?.data);
      })
      .catch((err) => console.error(err));
  }, [articleId]);

  React.useEffect(() => {
    getArticleById();
  }, []);
  return (
    <div className="article-page__component">
      {article && (
        <div className="article-page__component__article-wrapper">
          <div className="article-page__title-wrapper">
            <div className="article-page__title">{article.title}</div>
            <div className="article-page__date">
              {dateFormat(article.created_at)}
            </div>
          </div>
          {/* {article.imageLink && (
            <img className="article-page__image" src={`${article.imageLink}`} />
          )} */}
          <div className="article-page__description">
            {article.description}
            {article.imageLink && (
              <img
                className="article-page__image"
                src={`${article.imageLink}`}
              />
            )}
          </div>
          <div className="article-page__text">
            {parse(article.text.replaceAll("\n", "<br />"))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
function dataFormat(created_at: string): React.ReactNode {
  throw new Error("Function not implemented.");
}
