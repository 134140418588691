import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useGetAuthData } from "../../../components/Auth/useGetAuth";
import OInput from "../../../components/Form/Input";
import { genKey } from "../../../components/Helpers/genKey";
import { HOST_API } from "../../../config/CONSTANTS";
import "./Professions.scss";

const Professions: React.FC = () => {
  const [professions, setProfessions] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${HOST_API}/professions/`)
      .then((result) => {
        setProfessions(result?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div>
      <ProfessionsForm setProfessions={setProfessions} />
      <ProfessionsList
        professions={professions}
        setProfessions={setProfessions}
      />
    </div>
  );
};
const ProfessionsList: React.FC<{ professions: any; setProfessions: any }> = ({
  professions,
  setProfessions,
}) => {
  const { userId, token } = useGetAuthData();
  const removeProfession = React.useCallback((id: number) => {
    axios
      .post(
        `${HOST_API}/professions/remove/${id}`,
        {},
        {
          headers: { Authorization: token },
        }
      )
      .then((result) => {
        setProfessions((prev: any) =>
          prev.filter((item: any) => item.id !== id)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div>
      <h3 className="admin-field__edit">Список уже введенных профессий</h3>
      <div className="listOfProfessions">
        {professions?.map((prf: any, key: number) => (
          <div key={genKey(key)}>
            {prf.name}
            <button
              className="pages-profession_buttonDelete"
              onClick={() => removeProfession(prf.id)}
            >
              Удалить
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const ProfessionsForm: React.FC<{ setProfessions: any }> = ({
  setProfessions,
}) => {
  const { register, handleSubmit } = useForm();
  const { token, userId } = useGetAuthData();
  const SubmitForm = React.useCallback((data: any) => {
    axios
      .post(`${HOST_API}/professions/add`, data, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setProfessions((prev: any) => [...prev, response.data]);
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="profession_common">
      <h1 className="profession">Профессии</h1>
      <form className="add-stories__form" onSubmit={handleSubmit(SubmitForm)}>
        <OInput
          register={register}
          name="name"
          title="Введите название профессии"
        />
        <input
          type="submit"
          name="Добавить профессию"
          className="submit-button__form"
        />
      </form>
    </div>
  );
};

export default Professions;
