import axios from "axios";
import React from "react";
import { HOST_API } from "../../config/CONSTANTS";
import { StoryProps } from "../../pages/Stories/StoriesPage";
import "./Blog.scss";
import parse from "html-react-parser";
import dateFormat from "../Helpers/DateFormatter";

const BlogComponent: React.FC = () => {
  return (
    <div className="blog-component">
      <BlogNewsList />
    </div>
  );
};

const BlogNewsList: React.FC = () => {
  const [stories, setStories] = React.useState([]);

  const getArticles = React.useCallback((limit: number) => {
    axios
      .get(`${HOST_API}/stories/get/${limit}`)
      .then((result) => {
        result?.data && setStories(result?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    getArticles(4);
  }, []);

  return (
    <div className={`${stories.length > 1 ? "blogs-list-grid" : "blogs-list"}`}>
      {stories.length > 0 &&
        stories.map((story: StoryProps, key) => (
          <BlogElement {...story} key={key} />
        ))}
    </div>
  );
};

const BlogElement: React.FC<StoryProps> = ({
  title,
  description,
  videoLink,
  updated_at,
}) => {
  return (
    <div className="blog-element">
      <div className="blog-description">
        <div className="blog-header">
          <div className="blog-title">{title}</div>
          <div className="blog-date">{dateFormat(updated_at)}</div>
        </div>
        <div className="blog-text">{description}</div>
      </div>
      <div className="blog-video">{parse(videoLink)}</div>
      <div className="blog-share">
        <span className="blog-share__button">Поделиться</span>
        {/* TODO открывается в отдельном окне. у каждого репортажа - своя ссылка
        общая страница - репортажи */}
      </div>
    </div>
  );
};
export default BlogComponent;
