import React from "react";
import "./UsersPage.scss";
import axios from "axios";
import { HOST_API } from "../../../config/CONSTANTS";
import { useGetAuthData } from "../../../components/Auth/useGetAuth";
import dateFormat from "../../../components/Helpers/DateFormatter";

const UsersPage: React.FC = () => {
  const [listOfUsers, setListOfUsers] = React.useState<any[]>([]);
  const { token } = useGetAuthData();
  const getUserslist = () => {
    axios
      .get(`${HOST_API}/auth/getAll/`, { headers: { Authorization: token } })
      .then((res) => {
        console.log(res.data);
        setListOfUsers(res.data);
      });
  };
  React.useEffect(() => {
    getUserslist();
  }, []);

  const setUserActive = (userId: number, flag: boolean) => {
    axios
      .post(
        `${HOST_API}/auth/setActive`,
        { userId, flag },
        { headers: { Authorization: token } }
      )
      .then((res) => {
        console.log(res.data);
        const newList = listOfUsers.map((item: any) => {
          if (item?.id === res.data.id) {
            item.isActive = flag;
          }
          return item;
        });
        setListOfUsers(newList || []);
      });
  };

  React.useEffect(() => {
    console.log(listOfUsers);
  }, [listOfUsers]);
  const resultTable =
    listOfUsers?.length &&
    listOfUsers?.map(function (item: any) {
      return (
        <tr key={item.id}>
          <td>{item.email}</td>
          <td>{item.isActive ? "автоизован" : "не авторизован"}</td>
          <td>{item.profile.userName}</td>
          <td>{item.type}</td>
          <td>{dateFormat(item.profile.created_at)}</td>
          <td>
            <button
              className="buttonUsers toDo"
              onClick={() => setUserActive(item.id, true)}
            >
              авторизовать
            </button>
          </td>
          <td>
            <button
              className="buttonUsers toBlock"
              disabled={item.role === "admin"}
              onClick={() => setUserActive(item.id, false)}
            >
              заблокировать
            </button>
          </td>
        </tr>
      );
    });

  return (
    <div>
      <h1 className="usersPage-listTitle">Список пользователей</h1>
      <table>
        <thead>
          <tr>
            <td className="headerOfTable">email</td>
            <td className="headerOfTable">Авторизация</td>
            <td className="headerOfTable">Имя</td>
            <td className="headerOfTable">Тип</td>
            <td className="headerOfTable">Создан</td>
            <td className="headerOfTable">Авторизовать</td>
            <td className="headerOfTable">Заблокировать</td>
          </tr>
        </thead>
        <tbody>{resultTable}</tbody>
      </table>
    </div>
  );
};
export default UsersPage;
