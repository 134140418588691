import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import HeaderComponent from "./components/header/Header";
import Modal from "./components/Modal/Modal";
import UserPage from "./pages/UserPage/UserPage";
import MainPage from "./pages/Main/Main";
import AuthWrapper from "./components/Auth/AuthWrapper";
import AdminPage from "./pages/Admin/AdminPage";
import AuthPage from "./pages/Auth/AuthPage";
import ArticlePage from "./pages/Articles/ArticlePage";
import ArticlesPage from "./pages/Articles/ArticlesPage";
import StoriesPage from "./pages/Stories/StoriesPage";

const App: React.FC = () => {
  return (
    <div className="App">
      <AuthWrapper>
        <HeaderComponent />
        <div className="main-content-block">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/users/:userId" element={<UserPage />} />
            <Route path="/admin/*" element={<AdminPage />} />
            <Route path="/articles" element={<ArticlesPage />} />
            <Route path="/articles/:articleId" element={<ArticlePage />} />
            <Route path="/stories" element={<StoriesPage />} />
            <Route path="/auth" element={<AuthPage />} />
          </Routes>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default App;
