import axios from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAuthData } from "../../components/Auth/useGetAuth";
import { useRedirectToAuth } from "../../components/Auth/useRedirectToAuth";
import { HOST_API } from "../../config/CONSTANTS";
import UserHeader, { UserData, UserProfile } from "./UserHeader";
import "./UserPage.scss";

const UserPage: React.FC = () => {
  const { user: selfUser, token, setUserData, isAuth } = useGetAuthData();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [gettedUser, setGettedUser] = React.useState<UserData | undefined>(
    undefined
  );

  const getUser = React.useCallback(() => {
    const request = userId == selfUser.id ? "auth/checkAuth" : "auth";
    console.log(userId, selfUser);
    axios
      .get(`${HOST_API}/${request}/${userId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        if (!response?.data) {
          navigate(`/users/${selfUser.id}`);
        }
        setGettedUser(response?.data);
      })
      .catch((err) => {
        console.error(err);
        navigate(`/users/${selfUser.id}`);
      });
  }, [userId]);
  useRedirectToAuth();

  React.useEffect(() => {
    if (userId && token && isAuth) {
      getUser();
    }
  }, [userId, isAuth]);

  console.log(userId, selfUser.id);

  // if (Number(userId) !== Number(selfUser?.id) && !gettedUser) {
  //   setGettedUser();
  // }

  // const showUser = React.useMemo(() => {
  //   if (userId != selfUser.id && anotherUser) {
  //     return anotherUser;
  //   } else {
  //     return selfUser;
  //   }
  // }, [anotherUser, selfUser, userId]);
  return (
    <div className="userPage-component">
      {gettedUser && token && (
        <UserHeader
          {...gettedUser}
          canEdit={userId == selfUser.id}
          token={token}
        />
      )}
    </div>
  );
};

export default UserPage;
